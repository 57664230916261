import { differenceInDays } from 'date-fns';
import { transformStringToDate } from 'helpers/date-helpers';

export type TValidateBirthdayInputProps = {
  birthdayDate: string;
  alternativeAgeOfVerification: boolean;
};

export const validateBirthday = ({ birthdayDate, alternativeAgeOfVerification }: TValidateBirthdayInputProps) => {
  const birthday = transformStringToDate(birthdayDate);

  if (!birthday) return { isValid: false, isMedical: false };

  const currentDate = new Date();
  const ageOfVerification = alternativeAgeOfVerification ? 18 : 21;
  const ageEnteredInDays = differenceInDays(currentDate, birthday);

  const isValid = ageEnteredInDays >= ageOfVerification * 365;
  const isMedical = ageEnteredInDays >= 18 * 365 && ageEnteredInDays < 21 * 365;

  return { isValid, isMedical };
};
