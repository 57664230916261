import { Dispatch, SetStateAction } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import DOBField from 'components/molecules/form-fields/dob';
import { Formik, FormikErrors } from 'formik';
import { transformStringToDate } from 'helpers/date-helpers';
import { validateBirthday } from '../age-gate-helpers';
import { AgeGateAlternativeVerificationFormContainer } from '../alternative-verification-styles';
import AlternativeVerificationFormError from './alternative-verification-form-error';

type TAlternativeVerificationFormProps = {
  setAgeGateVisibilityCallback: Dispatch<SetStateAction<boolean>>;
  setDeniedVisibilityCallback: Dispatch<SetStateAction<boolean>>;
  onInvalidBirthdayCallback: () => void;
  onValidBirthdayCallback: () => void;
  onValidAgeForMedical: () => void;
};

type TBirthday = {
  birthdayDate: string;
};

export const AgeGateAlternativeVerificationFormComponent = ({
  setAgeGateVisibilityCallback,
  setDeniedVisibilityCallback,
  onInvalidBirthdayCallback,
  onValidBirthdayCallback,
  onValidAgeForMedical,
}: TAlternativeVerificationFormProps) => {
  const handleOnSubmit = ({ birthdayDate }: TBirthday) => {
    const isValidBirthday = validateBirthday({ birthdayDate, alternativeAgeOfVerification: true });

    if (isValidBirthday.isMedical) {
      setAgeGateVisibilityCallback(true);
      onValidAgeForMedical();
    } else if (isValidBirthday.isValid) {
      onValidBirthdayCallback();
    } else {
      setDeniedVisibilityCallback(true);
      onInvalidBirthdayCallback();
    }
  };

  const isSubmitDisabled = ({ birthdayDate }: TBirthday, errors: FormikErrors<TBirthday>) => {
    return birthdayDate.length !== 10 || Boolean(errors.birthdayDate);
  };

  return (
    <AgeGateAlternativeVerificationFormContainer data-testid="age-gate-alt-form-container">
      <Typography variant="h5" className="text-center uppercase" data-testid="birthdayText">
        Please enter your birthday
      </Typography>
      <Formik initialValues={{ birthdayDate: '' }} onSubmit={handleOnSubmit}>
        {({ setFieldError, submitForm, values, errors }) => {
          return (
            <>
              <div data-testid="age-gate-alt-form-fields-container" className="flex [&>div]:w-full">
                <DOBField
                  fieldName="birthdayDate"
                  label="mm/dd/yyyy"
                  placeholder=""
                  required
                  showErrorMessage={false}
                  clearErrorOnFocus={false}
                />
              </div>

              <CommonButton
                data-testid="age-gate-alt-form-submit-btn"
                button-type="primary"
                color="green"
                className="w-full"
                disabled={isSubmitDisabled(values, errors)}
                onClick={() => {
                  const birthdayDateValue = transformStringToDate(values.birthdayDate);

                  if (!birthdayDateValue) {
                    setFieldError('birthdayDate', 'Invalid date');
                    return null;
                  }

                  submitForm();
                }}
              >
                Submit
              </CommonButton>

              {!!errors.birthdayDate && <AlternativeVerificationFormError />}
            </>
          );
        }}
      </Formik>
    </AgeGateAlternativeVerificationFormContainer>
  );
};
