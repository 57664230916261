import Footer from 'components/organisms/global/footer/index';
import { getMenuLink } from 'helpers/utils/common';

const FooterComponent = (props: any) => {
  const { data, pageFolder } = props;

  if (!data?.footerMenu || data?.footerMenu?.dataSource?.isError) {
    return <></>;
  }
  const sectionFooterCollection = data?.footerMenu?.dataSource?.data?.sectionFooterCollection?.items?.[0] || {};
  const logo = sectionFooterCollection?.logo?.url;
  const logoAltText = sectionFooterCollection?.logo?.title;
  const backgroundObjectDesktop = {
    file: sectionFooterCollection?.backgroundImage?.desktopImage?.url,
    name: sectionFooterCollection?.backgroundImage?.desktopImage?.title,
    width: sectionFooterCollection?.backgroundImage?.desktopImage?.width,
    height: sectionFooterCollection?.backgroundImage?.desktopImage?.height,
  };
  const backgroundObjectMobile = {
    file: sectionFooterCollection?.backgroundImage?.mobileImage?.url,
    name: sectionFooterCollection?.backgroundImage?.mobileImage?.title,
    width: sectionFooterCollection?.backgroundImage?.mobileImage?.width,
    height: sectionFooterCollection?.backgroundImage?.mobileImage?.height,
  };
  const logoObject = {
    file: logo,
    name: logoAltText,
    width: sectionFooterCollection?.logo?.width,
    height: sectionFooterCollection?.logo?.height,
  };
  const legalCopyRight = sectionFooterCollection?.copyrightText;
  const chatCta = sectionFooterCollection?.chatCta?.title;
  const dispensaryData = data?.footerMenu?.dataSource?.data?.pageDispensaryCollection?.items[0];
  const customerCenterData = data?.footerMenu?.dataSource?.data?.pageContactUsCollection?.items[0];
  const gtiLogo = sectionFooterCollection?.gtiLogo;
  const shopUrl = getMenuLink();
  return (
    <Footer
      licenseData={dispensaryData}
      legalCopyRight={legalCopyRight}
      logo={logoObject}
      host={pageFolder?.host}
      backgroundImageDesktop={backgroundObjectDesktop}
      backgroundImageMobile={backgroundObjectMobile}
      chatCta={chatCta}
      socialMediaUrls={sectionFooterCollection?.socialMediaPresence}
      homeNavigationMenu={sectionFooterCollection?.navigationMenuAboutCollection?.items}
      tocNavigationMenu={sectionFooterCollection?.navigationMenuLegalCollection?.items}
      customerCenterData={customerCenterData}
      gtiLogo={gtiLogo}
      shopUrl={shopUrl}
    />
  );
};

export default FooterComponent;
