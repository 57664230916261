import { isValid, parse } from 'date-fns';

/**
 * Transforms a string in the format "MM/dd/yyyy" into a JavaScript Date object.
 *
 * @param dateString - A string representing a date in the format "MM/dd/yyyy".
 * @returns A Date object if the input string is valid, otherwise undefined.
 *
 * @remarks
 * - The input string must have a length of 10.
 * - The `month` should be two digits (e.g., "01" for January).
 * - The `day` should be two digits (e.g., "01" for the first day of the month).
 * - The `year` should be four digits (e.g., "2024").
 * - If the parsed date is invalid or does not match the original input, the function returns undefined.
 *
 * @example
 * ```ts
 * transformStringToDate("12/31/2024"); // Returns a valid Date object for December 31, 2024.
 * transformStringToDate("02/30/2024"); // Returns undefined because February 30 is invalid.
 * transformStringToDate("invalid-date"); // Returns undefined.
 * ```
 */

export const transformStringToDate = (dateString: string): Date | undefined => {
  if (!dateString || dateString.length !== 10) return undefined;

  const DATE_DELIMITER = '/';
  const [month = '', day = '', year = ''] = dateString.split(DATE_DELIMITER);

  if (month.length !== 2 || day.length !== 2 || year.length !== 4) return undefined;

  const monthNumber = parseInt(month, 10) - 1;
  const dayNumber = parseInt(day, 10);
  const yearNumber = parseInt(year, 10);

  const parsedDate = parse(dateString, `MM${DATE_DELIMITER}dd${DATE_DELIMITER}yyyy`, new Date());
  parsedDate.setFullYear(yearNumber); // fix year if it's a 2-digit year

  const isMonthValid = parsedDate.getMonth() === monthNumber;
  const isDayValid = parsedDate.getDate() === dayNumber;
  const isYearValid = parsedDate.getFullYear() === yearNumber;

  if (!isMonthValid || !isDayValid || !isYearValid || !parsedDate || !isValid(parsedDate)) return undefined;

  return parsedDate;
};
