import React from 'react';
import { Typography } from 'components/atoms/typography';
import Alert from 'public/icons/figma/alert.svg';

const AlternativeVerificationFormError = () => {
  return (
    <div className="flex items-center justify-center gap-2">
      <Alert className="text-error" />
      <Typography variant="body-large-bold" className="!text-grey-500">
        Please select a valid date
      </Typography>
    </div>
  );
};

export default AlternativeVerificationFormError;
