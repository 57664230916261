import { TProps, Typography } from 'components/atoms/typography';
import tw from 'tailwind-styled-components';
type THeaderSearchIconBoxProps = {
  $variant: 'dark' | 'light';
};
export const HeaderSearchIconBox = tw.button<THeaderSearchIconBoxProps>`
    hidden
    cursor-pointer
    fill-white
    p-5
    lg:block
    ${(p) =>
      p.$variant === 'dark'
        ? 'text-white  hover:text-leaf hover:bg-white hover:rounded-[32px]'
        : 'text-leaf hover:text-white hover:bg-leaf hover:rounded-[32px]'}
`;
export const HeaderIconBox = tw.button<THeaderSearchIconBoxProps>`
    lg:p-5
    cursor-default
    ${(p) => (p.$variant === 'dark' ? 'text-white' : 'text-leaf')}
`;

export const CartQuantityCount = tw.div`
    text-arial
    absolute
    -right-3
    -top-3
    flex
    h-[18px]
    w-[18px]
    items-center
    justify-center
    rounded-[9px]
    bg-resin
    py-[3px]
    text-xs
    font-bold
    text-white`;

export const HeaderIconCard = tw.div`
    col-span-1
    flex
    items-center
    justify-end
    gap-6
    lg:col-span-5
    lg:gap-0
`;

export const ProfileName: React.FC<TProps> = tw(Typography)`
  hidden
  lg:block
`;

export const SearchButtonConatiner = tw.div`
  absolute
  left-0
  top-[120px]
  md:top-[130px]
  lg:top-[152px] `;
